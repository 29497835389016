



















































import Vue from 'vue';
import API from '@/plugins/axios';
import { imageData } from '@/utils/utilsData';

export default Vue.extend({
  name: 'Skills',
  data: () => ({
    imageData: { ...imageData },
    skillsKitsData: [] as unknown[],
    skillsKitsDataCount: 0,
    skillsKitsFetched: false,
    skillsKitsDataPage: 1,
    loading: true,
  }),
  methods: {
    scrollTop() {
      this.$vuetify.goTo(0);
    },
    async getSkillsKits(nextPage?: boolean) {
      this.loading = true;
      if (!this.skillsKitsFetched || nextPage) {
        const { data } = await API.get(`/search/skills/maltese/?page=${this.skillsKitsDataPage}`);
        if (nextPage) {
          this.skillsKitsData = [...this.skillsKitsData, ...data.results];
        } else {
          this.skillsKitsData = data.results;
        }
        this.skillsKitsDataPage++;
        this.skillsKitsDataCount = data.count;
        this.skillsKitsFetched = true;
      }

      this.loading = false;
    },
  },
  async mounted() {
    this.getSkillsKits();
  },
});
